import React from 'react';
import { graphql, type PageProps } from 'gatsby';

import Seo from 'components/SEO';
import { ArticlePage, getFeaturedArticles } from 'modules/pr-articles';

const StrapiPrArticle = ({ data }: PageProps<Queries.StrapiPrArticleQuery>) => {
    if (!data?.strapiPrArticle) return null;

    const { seo, ...article } = data.strapiPrArticle;

    const metaImage = seo?.metaImage;
    const metaImageSize = metaImage ? { width: metaImage.width ?? 1200, height: metaImage.height ?? 630 } : undefined;

    const featuredArticles = getFeaturedArticles(data.strapiPrArticle, data.allStrapiPrArticle.nodes);

    return (
        <>
            <Seo
                title={seo?.metaTitle ?? article.title ?? ''}
                description={seo?.metaDescription ?? article.excerpt?.data?.childMdx?.excerpt}
                meta={seo?.keywords ? [{ name: 'keywords', content: seo.keywords }] : undefined}
                image={metaImage?.url ?? undefined}
                imageSize={metaImageSize}
            />
            <ArticlePage {...data} featuredArticles={featuredArticles} />
        </>
    );
};

export const pageQuery = graphql`
    query StrapiPrArticle($id: String!) {
        strapiPrArticle(id: { eq: $id }) {
            ...PrArticleDetail
        }
        allStrapiPrArticle(sort: { fields: publishedDate, order: DESC }) {
            nodes {
                ...PrArticle
            }
        }
    }
`;

export default StrapiPrArticle;
